import React from "react";
import { ChevronRightIcon, TrophyIcon } from "@heroicons/react/24/solid";
import { ThemedProgressCircle } from "./ProgressCircle";

const ChallengeCard = ({ challenge }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 max-w-sm flex flex-col matchallenge-card">
      <div className="flex items-center gap-4 mb-4">
        <div className="w-20 h-20 rounded-full shadow border-solid border-2 border-gray-100 px-4 py-3 flex justify-center items-center">
          <TrophyIcon className="w-12 h-12 text-school" />
        </div>
        <div>
          <h1 className="caption-1 text-gray-700">Donation {challenge.type}</h1>
          <h2 className="header-2 my-1">{challenge.title}</h2>
          <p className="caption-4">{challenge.subtitle}</p>
        </div>
      </div>
      <div className="my-6 w-full">
        { challenge.description && <p className="caption-2">{challenge.description}</p> }
      </div>
      <div className="flex items-center justify-between flex-1 gap-4">
        <div className="flex-1">
          <div className="font-bold text-sm text-gray-800 mb-8">
            {challenge.completed ? (
              <div className="header-2">
                Challenge Complete!
              </div>
            ) : (
              challenge.progress_text
            )}
          </div>
          <div>
            {challenge.directed && (
              <p className="text-sm text-gray-700">
                For <span className="font-bold">{challenge.directed_text}</span>
              </p>
            )}
            <p className="text-sm text-gray-700">
              Offered by <span className="font-bold">{challenge.donor_name}</span>
            </p>
          </div>
        </div>
        <div>
          <ThemedProgressCircle progress={challenge.progress} completed={challenge.completed}/>
        </div>
      </div>
      {!challenge.completed && (
        <div className="flex justify-end w-full mt-6">
          <a href={challenge.donate_url} className="text-school font-bold text-sm flex items-center gap-1 hover:text-gray-800">
            <span>Contribute</span>
            <ChevronRightIcon className="w-4 h-4" />
          </a>
        </div>
      )}
    </div>
  );
};

export default ChallengeCard;
